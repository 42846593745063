//import colors from 'vuetify/lib/util/colors'
//const colors = require('vuetify/lib/util/colors');

const tenantConfig = {
    default: {
        id: 'default',
        name: 'Sodisp',
        navPostfix: 'default',
        solutionsTranslationKey: 'nav.header.for-organizers',
        solutionsMenuKey: 'solutions-menu-default',
        title: 'Sodisp.com - Challenge and Results Platform',
        description: 'Platform to host and participate in connected and engaging events and challenges for endurance sports. Auto-sync with Garmin, Strava, Apple Watch, Fitbit, and many more.',
        isDefault: true,
        showFeatures: true,
        defaultApp: {
          bundleId: 'com.sodisp',
          appleStoreId: '1608944553',  
        },
        contactFormPostUrl: 'https://formspree.io/mlepzddz',
        supportFormPostUrl: 'https://formspree.io/mlepzddz',
        accountProviders: ['strava','garmin','sodisp'],
        availableProviders: ['strava','garmin','fitbit','underarmour','coros', 'suunto', 'polar','concept2','sodisp'],
        // old: analyticsPropertyId: 'UA-163164505-1',
        analyticsPropertyId: 'G-RWY6JL467J', // new GA4
        toolbarDark: true,
        scssVariables: 'src/styles/tenants/default',
        homeUrl: 'https://sodisp.com/',
        domain: 'sodisp.com',
        email: 'hello@sodisp.com',
        profileFaqUrl: 'https://corporatefitness.helpscoutdocs.com/collection/4-app',
        eventManagerFaqUrl: 'https://corporatefitness.helpscoutdocs.com/collection/1-eventmanager',
        headerLogoUrl: 'https://sodisp.imgix.net/web/logo-header.svg',
        favIconUrl: 'https://sodisp.imgix.net/web/favicon.png',
        logoUrl: 'https://sodisp.imgix.net/web/logo.png',
        blogCategorySlug: 'blog',
        enableStrava: true,
        contactEmail:  'hello@sodisp.com',
        appleAppSiteAssociationFile: 'apple-app-site-association-sodisp.json',
        theme: {
            primary: "#F46524",
            secondary: '#008AFF', // <-- new
            progress: '#FFEB3B', // <-- new//colors.yellow.base,
            accent: '#005398',
            green: '#00B46E',
            header: '#F46524', // <-- new
            gradient: '#FFC107', // <-- new
            eventmanager: '#00B46E', // <-- new
            profile: '#005398', // <-- new
          },
          subtenants: Object.freeze([
            {
                id: 'vruk',
                host: 'vr.co.uk',
                name: 'Virtual Racing UK',
                title: 'Virtual Racing UK',
                description: '',
                contactEmail: 'info@vr.co.uk',
                homeUrl: 'https://sodisp.com/',
                customCss: 'vruk.css',
                isDefault: false,
                showFeatures: false,
                navPostfix: 'minimal',
                customHomePostfix: 'whitelabel',
            },
            {
                id: 'asdeporte',
                host: 'retos.asdeporte.com',
                name: 'Asdeporte connected challenges',
                title: 'Asdeporte connected challenges',
                description: 'Transformamos la vida de las personas a través del Deporte.',
                homeUrl: 'https://retos.asdeporte.com/',
                logoUrl: '/images/tenants/asdeporte-logo.png',
                favIconUrl: '/images/tenants/asdeporte-favicon.png',
                headerLogoUrl: '/images/tenants/asdeporte-logo.png',
                accountProviders: ['asdeporte', 'garmin', 'strava'],
                customCss: 'asdeporte.css',
                solutionsMenuKey: null,
                isDefault: false,
                showFeatures: false,
                navPostfix: 'minimal',
                customHomePostfix: 'whitelabel',
                theme: {
                    primary: "#FF6A00",
                    secondary: '#2B2826', // <-- new
                    progress: '#FF6A00', // <-- new//colors.yellow.base,
                    accent: '#005398',
                    green: '#00B46E',
                    header: '#2B2826', // <-- new
                    gradient: '#000', // <-- new
                    eventmanager: '#2B2826', // <-- new
                    profile: '#FF5255', // <-- new
                }
            },
            {
                id: 'trimx',
                host: 'retos.triatlon.com.mx',
                name: 'Retos de Federación Mexicana de Triatlón',
                title: 'Retos de Federación Mexicana de Triatlón',
                description: 'Transformamos la vida de las personas a través del Deporte.',
                homeUrl: 'https://retos.triatlon.com.mx/',
                logoUrl: '/images/tenants/trimx-logo.png',
                favIconUrl: '/images/tenants/trimx-favicon.png',
                headerLogoUrl: '/images/tenants/trimx-logo.png',
                accountProviders: ['asdeporte', 'garmin', 'strava'],
                solutionsMenuKey: null,
                customCss: 'trimx.css',
                isDefault: false,
                showFeatures: false,
                navPostfix: 'minimal',
                customHomePostfix: 'whitelabel',
                theme: {
                    primary: "#cc0033",
                    secondary: '#2B2826', // <-- new
                    progress: '#FF6A00', // <-- new//colors.yellow.base,
                    accent: '#005398',
                    green: '#00B46E',
                    header: '#000', // <-- new
                    gradient: '#000', // <-- new
                    eventmanager: '#2B2826', // <-- new
                    profile: '#cc0033', // <-- new
                }
            },


            // DEMO TENANTS
            {
                id: 'athleta-demo',
                host: 'athleta-demo.sodisp.com',
                name: 'athleta connected challenges',
                title: 'athleta connected challenges',
                description: 'Welcome to the Athleta Connected Fitness Community! Get fit and challenge yourself. Connect your favorite GPS watch or app and get started!',
                homeUrl: 'https://athleta-demo.sodisp.com/',
                logoUrl: '/images/demos/athleta.png',
                headerLogoUrl: '/images/demos/athleta.png',
                customCss: 'demos.css',
                isDefault: false,
                showFeatures: false,
                navPostfix: 'minimal',
                customHomePostfix: 'whitelabel',
                theme: {
                    primary: "#942699",
                    secondary: '#008AFF', // <-- new
                    progress: '#942699', // <-- new//colors.yellow.base,
                    accent: '#005398',
                    green: '#00B46E',
                    header: '#fff', // <-- new
                    gradient: '#eee', // <-- new
                    eventmanager: '#00B46E', // <-- new
                    profile: '#005398', // <-- new
                }
            },
            {
                id: 'brooks-demo',
                host: 'brooks-demo.sodisp.com',
                name: 'Brooks connected challenges',
                title: 'Brooks connected challenges',
                description: 'Welcome to the Brooks Connected Fitness Community! Get fit and challenge yourself. Connect your favorite GPS watch or app and get started!',
                homeUrl: 'https://brooks-demo.sodisp.com/',
                logoUrl: '/images/demos/brooks.png',
                headerLogoUrl: '/images/demos/brooks.png',
                customCss: 'demos.css',
                isDefault: false,
                showFeatures: false,
                navPostfix: 'minimal',
                customHomePostfix: 'whitelabel',
                theme: {
                    primary: "#0363F7",
                    secondary: '#008AFF', // <-- new
                    progress: '#0363F7', // <-- new//colors.yellow.base,
                    accent: '#005398',
                    green: '#00B46E',
                    header: '#fff', // <-- new
                    gradient: '#eee', // <-- new
                    eventmanager: '#00B46E', // <-- new
                    profile: '#005398', // <-- new
                }
            },
            {
                id: 'coros-demo',
                host: 'coros-demo.sodisp.com',
                name: 'COROS connected challenges',
                title: 'COROS connected challenges',
                description: 'Welcome to the COROS Connected Fitness Community! Get fit and challenge yourself. Connect with your COROS account and your activities will be synced automatically!',
                homeUrl: 'https://coros-demo.sodisp.com/',
                logoUrl: '/images/demos/coros.png',
                headerLogoUrl: '/images/demos/coros.png',
                accountProviders: ['coros'],
                customCss: 'demos.css',
                isDefault: false,
                showFeatures: false,
                navPostfix: 'minimal',
                customHomePostfix: 'whitelabel',
                theme: {
                    primary: "#F6283B",
                    secondary: '#008AFF', // <-- new
                    progress: '#0363F7', // <-- new//colors.yellow.base,
                    accent: '#005398',
                    green: '#00B46E',
                    header: '#fff', // <-- new
                    gradient: '#eee', // <-- new
                    eventmanager: '#00B46E', // <-- new
                    profile: '#005398', // <-- new
                }
            },
            {
                id: 'inov-8-demo',
                host: 'inov-8-demo.sodisp.com',
                name: 'Inov-8 connected challenges',
                title: 'Inov-8 connected challenges',
                description: 'Welcome to the Inov-8 Connected Fitness Community! Get fit and challenge yourself. Connect your favorite GPS watch or app and get started!',
                homeUrl: 'https://inov-8-demo.sodisp.com/',
                logoUrl: '/images/demos/inov-8.png',
                headerLogoUrl: '/images/demos/inov-8.png',
                customCss: 'demos.css',
                isDefault: false,
                showFeatures: false,
                navPostfix: 'minimal',
                customHomePostfix: 'whitelabel',
                theme: {
                    primary: "#000000",
                    secondary: '#008AFF', // <-- new
                    progress: '#FFEB3B', // <-- new//colors.yellow.base,
                    accent: '#005398',
                    green: '#00B46E',
                    header: '#fff', // <-- new
                    gradient: '#eee', // <-- new
                    eventmanager: '#00B46E', // <-- new
                    profile: '#005398', // <-- new
                }
            },
            {
                id: 'lululemon-demo',
                host: 'lululemon-demo.sodisp.com',
                name: 'lululemon connected challenges',
                title: 'lululemon connected challenges',
                description: 'Welcome to the lululemon Connected Fitness Community! Get fit and challenge yourself. Connect your favorite GPS watch or app and get started!',
                homeUrl: 'https://lululemon-demo.sodisp.com/',
                logoUrl: '/images/demos/lululemon.png',
                headerLogoUrl: '/images/demos/lululemon.png',
                customCss: 'demos.css',
                isDefault: false,
                showFeatures: false,
                navPostfix: 'minimal',
                customHomePostfix: 'whitelabel',
                theme: {
                    primary: "#CF052B",
                    secondary: '#008AFF', // <-- new
                    progress: '#CF052B', // <-- new//colors.yellow.base,
                    accent: '#005398',
                    green: '#00B46E',
                    header: '#fff', // <-- new
                    gradient: '#eee', // <-- new
                    eventmanager: '#00B46E', // <-- new
                    profile: '#005398', // <-- new
                }
            },
            {
                id: 'polar-demo',
                host: 'polar-demo.sodisp.com',
                name: 'Polar connected challenges',
                title: 'Polar connected challenges',
                description: 'Welcome to the Polar Connected Fitness Community! Get fit and challenge yourself. Connect with your Polar account and your activities will be synced automatically!',
                homeUrl: 'https://polar-demo.sodisp.com/',
                logoUrl: '/images/demos/polar.png',
                headerLogoUrl: '/images/demos/polar.png',
                accountProviders: ['polar'],
                customCss: 'demos.css',
                isDefault: false,
                showFeatures: false,
                navPostfix: 'minimal',
                customHomePostfix: 'whitelabel',
                theme: {
                    primary: "#ED1638",
                    secondary: '#008AFF', // <-- new
                    progress: '#ED1638', // <-- new//colors.yellow.base,
                    accent: '#005398',
                    green: '#00B46E',
                    header: '#fff', // <-- new
                    gradient: '#eee', // <-- new
                    eventmanager: '#00B46E', // <-- new
                    profile: '#005398', // <-- new
                }
            },
            {
                id: 'salomon-demo',
                host: 'salomon-demo.sodisp.com',
                name: 'Salomon connected challenges',
                title: 'Salomon connected challenges',
                description: 'Welcome to the Salomon Connected Fitness Community! Get fit and challenge yourself. Connect your favorite GPS watch or app and get started!',
                homeUrl: 'https://salomon-demo.sodisp.com/',
                logoUrl: '/images/demos/salomon.png',
                headerLogoUrl: '/images/demos/salomon.png',
                customCss: 'demos.css',
                isDefault: false,
                showFeatures: false,
                navPostfix: 'minimal',
                customHomePostfix: 'whitelabel',
                theme: {
                    primary: "#FF0B0A",
                    secondary: '#008AFF', // <-- new
                    progress: '#FFEB3B', // <-- new//colors.yellow.base,
                    accent: '#005398',
                    green: '#00B46E',
                    header: '#FF0B0A', // <-- new
                    gradient: '#EC7102', // <-- new
                    eventmanager: '#00B46E', // <-- new
                    profile: '#005398', // <-- new
                }
            },
            {
                id: 'suunto-demo',
                host: 'suunto-demo.sodisp.com',
                name: 'Suunto connected challenges',
                title: 'Suunto connected challenges',
                description: 'Welcome to the Suunto Connected Fitness Community! Get fit and challenge yourself. Connect with your Suunto account and your activities will be synced automatically!',
                homeUrl: 'https://suunto-demo.sodisp.com/',
                logoUrl: '/images/demos/suunto.png',
                headerLogoUrl: '/images/demos/suunto.png',
                accountProviders: ['suunto'],
                customCss: 'demos.css',
                isDefault: false,
                showFeatures: false,
                navPostfix: 'minimal',
                customHomePostfix: 'whitelabel',
                theme: {
                    primary: "#E1001A",
                    secondary: '#008AFF', // <-- new
                    progress: '#FFEB3B', // <-- new//colors.yellow.base,
                    accent: '#005398',
                    green: '#00B46E',
                    header: '#fff', // <-- new
                    gradient: '#eee', // <-- new
                    eventmanager: '#00B46E', // <-- new
                    profile: '#005398', // <-- new
                }
            },

        ]),
    },
    wmm: {
        id: 'wmm',
        appScheme: 'wmm-grc',
        name: 'Global Run Club',
        navPostfix: 'wmm',
        accountMenuKey: 'account-menu-wmm',
        customHomePostfix: 'whitelabel',
        customRoutesPostfixes: {
            home: 'whitelabel',
            contact: 'whitelabel',
            about: 'wmm',
        },
        title: 'Abbott World Marathon Majors Global Run Club',
        isDefault: false,
        showFeatures: false,
        accountProviders: ['strava','garmin', 'sodisp'],
        stravaClientId: 'todo',
        enableStrava: false,
        iOSAppStoreId: '6651858564',
        androidPackageId: 'com.abbottwmmglobalrunclub.app',
        //analyticsPropertyId: 'UA-158047576-1',
        scssVariables: 'src/styles/tenants/wmm',
        // test: 
        //homeUrl: 'https://grc-qa.worldmarathonmajors.com/',
        // prod:
        homeUrl: 'https://grc.worldmarathonmajors.com/',
        availableProviders: ['strava','garmin','fitbit','underarmour','coros', 'suunto', 'polar','concept2','sodisp'],
        toolbarDark: true,
        customHomePostfix: 'wmm',
        toolbarCentered: true,
        hideManualEntry: true,
        rankedGroupCategory: 'countries',
        hasTenantTeam: true,
        headerLogoUrl: 'https://cdn.prod.website-files.com/5f3f7d6ec9234e4bc8f23266/5f3f85ec5d10292123f416cb_logo.svg',
        favIconUrl: 'https://cdn.prod.website-files.com/5f3f7d6ec9234e4bc8f23266/5f3f7ff9e63285db8c1482b3_favicon.png',
        logoUrl: 'https://cdn.prod.website-files.com/5f3f7d6ec9234e4bc8f23266/5f3f85ec5d10292123f416cb_logo.svg',
        profileFaqUrl: 'https://corporatefitness.helpscoutdocs.com/collection/4-app',
        contactEmail:  'grc@wmmajors.com',
        // authDomain: null, // leave empty to take from .env settings
        ssoConfig: {
            enabled: true,
            required: true,
            firebase_provider: null, // leave empty to take from .env settings
        },
        stripeConfig: {
            connectedAccountId: 'acct_1PrKp42cOpYovcZA',
        },
        theme: {
            primary: "#003d64",
            secondary: '#faae40', // <-- new
            progress: '#faae40', // <-- new
            accent: '#009cde',
            green: '#00B46E',
            header: '#003d64', // <-- new
            gradient: '#009cde', // <-- new
            eventmanager: '#ECEFF1', // <-- new
            profile: '#009cde', // <-- new
        },
    },
    fitmap: {
        id: 'fitmap',
        name: 'FitMap',
        navPostfix: 'default', 
        solutionsTranslationKey: 'nav.header.for-organizers',
        solutionsMenuKey: 'solutions-menu-default',
        title: 'FitMap - Challenge and Results Platform',
        description: 'Platform to host and participate in connected and engaging events and challenges for endurance sports. Auto-sync with Garmin, Strava, Apple Watch, Fitbit, and many more.',
        isDefault: true,
        showFeatures: true,
        toolbarDark: false,
        defaultApp: {
          bundleId: 'com.sodisp', // todo
          appleStoreId: '1608944553',   // todo
        },
        accountProviders: ['strava','garmin','sodisp'],
        availableProviders: ['strava','garmin','fitbit','underarmour','coros', 'suunto', 'polar','concept2','sodisp'],
        analyticsPropertyId: 'G-RWY6JL467J', // new GA4
        contactFormPostUrl: 'https://formspree.io/mlepzddz',
        supportFormPostUrl: 'https://formspree.io/mlepzddz',
        scssVariables: 'src/styles/tenants/fitmap',
        homeUrl: 'https://fitmap.app/',
        domain: 'fitmap.app',
        email: 'hello@fitmap.app',
        profileFaqUrl: 'https://corporatefitness.helpscoutdocs.com/collection/4-app',
        eventManagerFaqUrl: 'https://corporatefitness.helpscoutdocs.com/collection/1-eventmanager',
        headerLogoUrl: 'https://sodisp.imgix.net/web/fitmap/logo-fitmap-v2.svg', // todo
        favIconUrl: 'https://sodisp.imgix.net/web/fitmap/icon-fitmap-v2.png', // todo
        logoUrl: 'https://sodisp.imgix.net/web/fitmap/logo-fitmap-v2.png', // todo
        blogCategorySlug: 'blog',
        contactEmail:  'hello@fitmap.app',
        enableStrava: true,
        appleAppSiteAssociationFile: 'apple-app-site-association-sodisp.json',// todo
        theme: {
            primary: "#F46524",
            secondary: '#008AFF', // <-- new
            progress: '#333', // <-- new//colors.yellow.base,
            blue: '#008aff',
            accent: '#005398', // dark blue
            green: '#00b46e', //
            header: '#fff', // <-- new
            gradient: '#fafafa', // <-- new
            eventmanager: '#00B46E', // <-- green
            profile: '#005398', // <-- dark blue
        },
        subtenants: Object.freeze([
            {
                id: '100kmc',
                host: 'portal.100kmc.com',
                //host: '100kmc.netlify.app',
                name: '100KMC',
                title: '100KMC',
                description: 'Try to log 100,000 meters this December while supporting our charity partner, Carry The Load.',
                contactEmail: 'hello@100kmc.com',
                homeUrl: 'https://portal.100kmc.com/',
                logoUrl: 'https://100kmc.com/wp-content/uploads/2020/08/100KMC-Long-Form-Logo-300x58.png',
                headerLogoUrl: 'https://100kmc.com/wp-content/uploads/2020/08/100KMC-Long-Form-Logo-300x58.png',
                favIconUrl: 'https://100kmc.com/wp-content/uploads/2020/08/cropped-100KMC-Long-Form-Logo-STACKED-01-192x192.png',
                customCss: '100kmc.css',
                isDefault: false,
                showFeatures: false,
                solutionsMenuKey: null, // force to null so it's disabled
                navPostfix: '100kmc',
                accountMenuKey: 'account-menu-minimal',
                footerNavKey: 'footer-none',
                customHomePostfix: 'singleevent',
                primaryEventId: '100kmc-2023',
                stravaClientId: '113697',
                theme: {
                    primary: "#f15729", // <-- 'oranje'
                    secondary: '#4A6392', // <-- dof blauw
                    progress: '#263238', // <-- new
                    accent: '#f15729',
                    green: '#00B46E',
                    header: '#fff', // <-- new
                    gradient: '#fff', // <-- new
                    eventmanager: '#00B46E', // <-- green
                    profile: '#005398', // <-- dark blue
                },
                activityTypes: {
                    'WALKING': null,
                    'RUNNING': null,
                    'HIKING': 'Rucking',
                    'CYCLING': null,
                    'OTHER': 'Assault Bike / Airbike',
                    'ROWING': null,
                    'SWIMMING': null,
                }
            },
        ]),
    },
    demo: {
        id: 'demo',
        name: 'sodisp.com',
        navPostfix: 'minimal',
        title: 'sodisp - Challenge Platform',
        isDefault: false,
        showFeatures: false,
        accountProviders: ['sodisp', 'garmin', 'strava'],
        analyticsPropertyId: 'UA-158047576-1',
        scssVariables: 'src/styles/tenants/demo',
        homeUrl: 'https://demo.sodisp.com/',
        headerLogoUrl: 'https://sodisp.imgix.net/~text?txt-color=fff&txt-pad=0&txt64=RGVtbyBQbGF0Zm9ybQ&txt-font=Avenir%20Next%20Condensed%2CBold&w=200&txt-size=32&fm=png',
        logoUrl: 'https://sodisp.imgix.net/~text?txt-color=F46524&txt-pad=0&txt64=RGVtbyBQbGF0Zm9ybQ&txt-font=Avenir%20Next%20Condensed%2CBold&w=200&txt-size=32&fm=png',
        contactEmail:  'hello@sodisp.com',
        customHomePostfix: 'whitelabel',
        theme: {
            primary: "#F46524",
            secondary: '#008AFF', // <-- new
            progress: '#FFEB3B', // <-- new //colors.yellow.base,
            accent: '#005398',
            green: '#00B46E',
            header: '#00B46E', // <-- new
            gradient: '#00995B', // <-- new
            eventmanager: '#00B46E', // <-- new
            profile: '#005398', // <-- new
        },
        subtenants: Object.freeze([
        ]),
    },
    cofi: {
        id: 'cofi',
        appScheme: 'cofi',
        name: 'CorporateFitness.app',
        navPostfix: 'cofi',
        solutionsMenuKey: 'solutions-menu-cofi',
        cmsMenuKey: 'nav-menu-cofi',
        title: 'CorporateFitness.app - Connected Corporate Fitness Programs',
        description: 'Create and host engaging company wellbeing challenges. Integrates with Slack. Auto-sync from Apple Watch, Garmin, Strava and many more.',
        isDefault: false,
        showFeatures: true,
        slackAppId: 'A02UBT69ME1',
        //analyticsPropertyId: 'G-CTDT2Z4D7K', // old UA tracker
        analyticsPropertyId: 'G-CG4T9RTCRC', //new UA4 tracker
        contactFormPostUrl: 'https://formspree.io/mpzknpqw',
        supportFormPostUrl: 'https://formspree.io/xgeqqwlq',
        accountProviders: ['sodisp', 'garmin', 'strava'],
        availableProviders: ['strava','garmin','fitbit','underarmour','coros', 'suunto', 'polar','concept2','microsoft','sodisp'],
        toolbarDark: true,
        scssVariables: 'src/styles/tenants/cofi',
        homeUrl: 'https://corporatefitness.app/',
        domain: 'corporatefitness.app',
        email: 'hello@corporatefitness.app',
        profileFaqUrl: 'https://corporatefitness.helpscoutdocs.com/collection/4-app',
        eventManagerFaqUrl: 'https://corporatefitness.helpscoutdocs.com/collection/1-eventmanager',
        favIconUrl: '/images/tenants/cofi-favicon.png',
        headerLogoUrl: '/images/logo-cofi-header.png',
        logoUrl: '/images/logo-cofi.png',
        contactEmail:  'hello@corporatefitness.app',
        customHomePostfix: 'cofi',
        appleAppSiteAssociationFile: 'apple-app-site-association-cofi.json',
        stravaClientId: '74196',
        enableStrava: true,
        iOSAppStoreId: '1594403776',
        androidPackageId: 'app.corporatefitness',
        authDomain: 'auth.corporatefitness.app', // https://firebase.google.com/docs/auth/web/redirect-best-practices
        blogCategorySlug: 'blog-cofi',
        theme: {
            primary: "#008F58",
            secondary: '#008AFF', // <-- new
            progress: '#FFEB3B', // <-- new //colors.yellow.base,
            accent: '#005398',
            green: '#00B46E',
            header: '#00B46E', // <-- new
            gradient: '#00995B', // <-- new
            eventmanager: '#008F58', // <-- darker green
            profile: '#005398', // <-- new
        },
        themeHighContrast: {
            primary: '#c6521d',
            secondary: '#0076da',
            accent: '#3f51b5',
            error: '#e91e63',
            warning: '#f44336',
            info: '#03a9f4',
            success: '#8bc34a',
            header: '#008752',
            gradient: '#00673e',
            anchor: '#963e16',
        },
        subtenants: Object.freeze([
        ]),
    },
    /*enterprise: {
        id: 'enterprise',
        name: 'sodisp.com',
        navPostfix: 'minimal',
        title: 'sodisp - Challenge Platform', //TODO
        isDefault: false,
        showFeatures: false,
        accountProviders: ['sodisp', 'garmin', 'strava'],
        scssVariables: 'src/styles/tenants/demo',
        homeUrl: 'https://demo.sodisp.com/',//TODO
        headerLogoUrl: 'https://sodisp.imgix.net/~text?txt-color=fff&txt-pad=0&txt64=RGVtbyBQbGF0Zm9ybQ&txt-font=Avenir%20Next%20Condensed%2CBold&w=200&txt-size=32&fm=png',
        logoUrl: 'https://sodisp.imgix.net/~text?txt-color=F46524&txt-pad=0&txt64=RGVtbyBQbGF0Zm9ybQ&txt-font=Avenir%20Next%20Condensed%2CBold&w=200&txt-size=32&fm=png',
        contactEmail:  'hello@sodisp.com',//TODO
        theme: {
            primary: "#F46524",
            secondary: '#008AFF', // <-- new
            progress: '#FFEB3B', // <-- new //colors.yellow.base,
            accent: '#005398',
            green: '#00B46E',
            header: '#00B46E', // <-- new
            gradient: '#00995B', // <-- new
            eventmanager: '#00B46E', // <-- new
            profile: '#005398', // <-- new
        },
        subtenants: [
            {
                id: 'vruk',
                host: 'vr.co.uk',
                name: 'Virtual Racing UK',
                title: 'Virtual Racing UK',
                contactEmail: 'info@vr.co.uk',
            }
        ],
    },*/
    truenorth: {
        id: 'truenorth',
        name: 'True North Challenges',
        navPostfix: 'truenorth',
        accountMenuKey: 'account-menu-minimal',
        customHomePostfix: 'whitelabel',
        title: 'True North Challenges',
        isDefault: false,
        showFeatures: false,
        accountProviders: ['strava','garmin', 'sodisp'],
        stravaClientId: '113927',
        iOSAppStoreId: '6469623339',
        androidPackageId: 'com.truenorthchallenges.tracker',
        //analyticsPropertyId: 'UA-158047576-1',
        scssVariables: 'src/styles/tenants/truenorth',
        homeUrl: 'https://tracker.truenorthchallenges.com/',
        availableProviders: ['strava','garmin','fitbit','underarmour','coros', 'suunto', 'polar','concept2','sodisp'],
        toolbarDark: false,
        toolbarCentered: true,
        headerLogoUrl: '/logo-header.png',
        favIconUrl: '/favicon.png',
        logoUrl: '/logo.png',
        enableStrava: true,
        contactEmail:  'info@truenorthchallenges.com',
        theme: {
            primary: "#d71f27",
            secondary: '#d94b20', // <-- new
            progress: '#d94b20', // <-- new
            accent: '#d94b20',
            green: '#00B46E',
            header: '#fff', // <-- new
            gradient: '#fff', // <-- new
            eventmanager: '#ECEFF1', // <-- new
            profile: '#d71f27', // <-- new
          },
    },
    eventscom: {
        id: 'eventscom',
        name: 'EveryChallenge',
        navPostfix: 'minimal',
        customHomePostfix: 'eventscom',
        title: 'EveryChallenge - Virtual Challenges',
        isDefault: false,
        showFeatures: false,
        accountProviders: ['strava','garmin', 'eventscom','sodisp'],
        analyticsPropertyId: 'UA-158047576-1',
        scssVariables: 'src/styles/tenants/eventscom',
        homeUrl: 'https://everychallenge.events.com/',
        profileFaqUrl: 'https://events.com/faq/',
        eventManagerFaqUrl: 'https://events.com/faq/everychallenge-faq/',
        headerLogoUrl: '/images/eventscom-white.png',
        favIconUrl: '/images/tenants/eventscom-favicon.png',
        logoUrl: 'https://events.com/wp-content/themes/events_com_v2/assets/images/logos/events_com_logo.png',
        contactEmail:  'koen@sodisp.com',
        theme: {
            primary: "#ff5722",
            secondary: '#00bcda', // <-- new
            progress: '#263238', // <-- new
            accent: '#00bcda',
            green: '#00B46E',
            header: '#00bcda', // <-- new
            gradient: '#008DA3', // <-- new
            eventmanager: '#ECEFF1', // <-- new
            profile: '#00bcda', // <-- new
          },
    }
}

module.exports = {
    config: tenantConfig,

    get(tenantId) {
        return this.config[tenantId];
    },
    current() {
        var baseTenantId = process.env.VUE_APP_TENANT;
        var baseTenant;
        if (typeof window === typeof undefined) {
            // compile time
            baseTenant = this.config[baseTenantId];
            if (!baseTenant) {
                baseTenant = Object.values(this.config).find(x => x.subtenants && x.subtenants.some(y => y.id == baseTenantId));
                tenant = baseTenant.subtenants.find(x => x.id == baseTenantId);
                return {...baseTenant, ...tenant};
            }
            return baseTenant;
        }
        baseTenantId = new URLSearchParams(window.location.search).get('baseTenant') || baseTenantId;
        var queryValue = new URLSearchParams(window.location.search).get('tenant');
        var host = window.location.host;
        //console.log('Looking up tenant', baseTenantId, window.location.host, queryValue);
        baseTenant = this.config[baseTenantId];
        var tenant = null;
        if (baseTenant.subtenants) {
            tenant = baseTenant.subtenants.find(x => x.host == host || x.id == queryValue);
        }
        if (tenant == null) {
            tenant = this.config[queryValue];
        }
        const mergedTenant = {...baseTenant, ...tenant};
        //console.log('Looked up tenant', baseTenant, tenant, mergedTenant);
        return mergedTenant;
    },
};
//export default tenantConfig;